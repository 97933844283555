<template>
  <div>
    <portal to="page-top-title">CategoryGroups</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'categoryGroups-new' }" icon="fa-plus" text>Add New</btn>
    </portal>

    <box-table title="List" url="/category-groups" data-prop="groups" loader>
      <template slot="table-header">
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th class="text-center">Active</th>
          <th class="text-right">Created At</th>
          <th class="text-right">Updated At</th>
          <th class="text-right"></th>
        </tr>
      </template>
      <template slot="table-body">
        <table-row v-for="group in groups" :key="`groups-tr-${group.id}`" :value="group">
          <td>{{ group.id }}</td>
          <td>{{ group.name }}</td>
          <td class="text-center">
            <badge-active :value="group.active"/>
          </td>
          <td class="text-right">{{ group.created_at }}</td>
          <td class="text-right">{{ group.updated_at }}</td>
          <td class="text-right">
            <btn :route="{ name: 'categoryGroups-edit', params: { id: group.id } }" icon="fa-edit">Edit</btn>
            <btn :route="{ name: 'categoryGroups-single', params: { id: group.id } }" color="info" icon="fa-eye">Show</btn>
          </td>
        </table-row>
      </template>
    </box-table>
  </div>
</template>

<script>
import BoxTable from '@/views/components/data/BoxTable'
import BadgeActive from '@/views/components/simple/BadgeActive'
import Btn from '@/views/components/simple/Btn'

export default {
  name: 'CategoryGroupsList',
  metaInfo: {
    title: 'CategoryGroups'
  },
  components: {
    BoxTable,
    BadgeActive,
    Btn
  },
  data () {
    return {
      groups: []
    }
  }
}
</script>
